<template>
  <div class="row home m-0">
    <div class="col-md-12 homeWrap">
      <div class="row h-100 d-flex align-items-center">
        <div class="row">
          <div class="col-md-1">
          </div>
          <div class="col-md-2 col-xs-12">
            <MagLogo></MagLogo>
          </div>
          <div class="col-md-6">
          </div>
          <div class="col-md-3">
<!--            <p>-->
<!--              Vermont Primary Election<br />-->
<!--              Date: Tuesday, August 13, 2024-->
<!--            </p>-->
            <p>
              Vermont General Election<br />
              Date: Tuesday, November 5, 2024
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
          </div>

          <div class="col-md-6  text-center">
            <h1>
              John Kascenska is a House candidate for election to the legislature and represent all towns in the Essex-Caledonia district
            </h1>
            <h6>Bloomfield, Brunswick, Burke, East Haven, Ferdinand,<br/>
              Granby, Guildhall, Lunenburg, Maidstone, and Victory

            </h6>
          </div>
          <div class="col-md-3"></div>
        </div>
        <div class="row">
          <div class="col-md-12">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 bg-danger">
          </div>
        </div>
      </div>
<!--      <div class="row m-0">-->
<!--        <div class="col-md-12 m-0">-->
<!--          Test-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
<!--  <div class="row m-0">-->
<!--    <div class="col-md-12 bg-danger p-2 text-center">-->
<!--      <a class="btn btn-outline-light" href="#" role="button">Donate to Campaign coming soon!</a>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
import MagLogo from '../components/MagLogo';


export default {
  name: "HomePlacard",
  components: {
    MagLogo,
  }
}
</script>

<style scoped>

.home {
  background-image: url("../assets/vermont-autumn-landscape-1446126956Zbs.jpg");
  height: 90vh;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;

  color: white;
  font-variant: small-caps;
}
.homeWrap{
  background: gray; /* older browsers */
  background: rgba(0,0,0,0.55); /* newer browsers */
  height: 100%;
}
.homeBody{
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
h1{
  margin: 0;
}
</style>
