<template>
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-md-12">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <router-link to="/">Home</router-link>
<!--          <div class="ms-auto">Vote August 9th</div>-->
        </nav>
      </div>
    </div>
    <router-view/>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body{
  margin: 0;
}
nav {
  padding: 30px;
}
a {
  font-weight: bold;
  color: rgb(79, 121, 168);

  &.router-link-exact-active {
    color: rgb(79, 121, 168);
  }
}
</style>

<script>
// import VotedAlert from './components/VotedAlert';
// @ is an alias to /src

export default {
  name: 'App',
  components:{
    // VotedAlert,
  }
}
</script>

