<template>
  <div class="w-auto m-auto">
    <table>
      <tr>
        <td><img src="../assets/Picture1.png"></td>
        <td><img src="../assets/Picture1.jpg"></td>
      </tr>
      <tr>
        <td colspan="2">
          <p class="text-center"><b>Representing The Needs Of The NEK</b></p>
          <p>Bringing balance and common sense back to the Legislature.</p>
        </td>
      </tr>
    </table>

  </div>
</template>

<script>
export default {
  name: "MagLogo"
}
</script>

<style scoped>
.maglogo{
  max-width: 15vw;
}
img{
  object-fit: contain;
  height: 100%;
  width: 100%;
}
</style>
