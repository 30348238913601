<template>
  <div class="row m-0 qpBox">
    <div class="col-md-12 py-5 m-auto qpMsg">
      <p>During my term in the 2022 Legislature, I followed through on Governor Scott’s assessment of my qualifications and confidence to serve the district well. You can count on me to do the same and serve the people of the Essex-Caledonia District well by being a strong advocate and voice for every small town across the rural landscape of The Northeast Kingdom. It is clear there are common issues affecting all Vermonters. As I have traveled across the Essex-Caledonia District during the past two years, residents have voiced several concerns that require a commonsense approach and solution-based timeframes to collectively move our state in a better direction.
        <ul>
          <li>Available and affordable housing.</li>
          <li>Fiscally sound and reliable support for schools across the state.</li>
          <li>Support for our first responders to assure best safety and emergency response regarding law enforcement, fire, and ambulance services.</li>
          <li>Support for agricultural and. forestry industries</li>
          <li>Development of actionable environmental policy and practices to best respond to unprecedented weather events affecting residential and small business across the state.</li>
          <li>Workforce growth across career sectors to support local and regional service needs.</li>
          <li>Balancing the state budget to adequately fund current obligations and additional needs in our state.</li>
        </ul>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuotePhil"
}
</script>

<style scoped>
a {
  font-weight: bold;
  color: rgb(79, 121, 168);
}
.quoteText{
  font-size: 24px;
  font-style: italic;
}
.qpBox{
  background-color: rgb(245,245,245);
}

.qpMsg{
  width: 95%;
}
</style>
