<template>
  <div class="row m-0">
    <div class="col-md-12 text-center">
      <hr class="wave">
      <hr class="shine">
      <div class="bodyOfText w-75 mx-auto">
        Contributions to support John’s election as he travels across each of the 10 towns to meet with district voters are always appreciated and can be sent to <br/>
        <b>Kascenska for Vermont, P.O. Box 41, East Burke, VT 05832</b>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DonateBox"
}
</script>

<style scoped>
.h-divider {
  margin: auto;
  margin-top: 80px;
  width: 80%;
  position: relative;
}
.h-divider .shadow {
  overflow: hidden;
  height: 20px;
}
.h-divider .shadow:after {
  content: '';
  display: block;
  margin: -25px auto 0;
  width: 100%;
  height: 25px;
  border-radius: 125px/12px;
  box-shadow: 0 0 8px black;
}
*:before, *:after { content: ''; }
hr {
  border: 0;
  margin: 1.35em auto;
  max-width: 100%;
  background-position: 50%;
  box-sizing: border-box;
}


/* Shine */
.shine {
  height: 15px;
  width: 100%;
  background-image: radial-gradient(
      farthest-side at 50% -50%,
      hsla(0, 0%, 0%, 0.5),
      hsla(0, 0%, 0%, 0));
  position: relative;
}

.shine::before {
  height: 1px;
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  background-image: linear-gradient(
      90deg,
      hsla(0, 0%, 0%, 0),
      hsla(0, 0%, 0%, 0.75) 50%,
      hsla(0, 0%, 0%, 0));
}
.bodyOfText{
  margin: 4vh 1vw;
}
</style>
