<template>
  <div class="row p-4 m-0">
    <div class="col-md-4">

    </div>
    <div class="col-md-4 text-center">
      Paid for by Kascenska For Vermont, P.O. Box 41, East Burke, VT 05832
    </div>
    <div class="col-md-4">

    </div>
  </div>
</template>

<script>
export default {
  name: "FooterBar"
}
</script>

<style scoped>

</style>
