<template>
  <div class="row m-0">
    <div class="col-md-6 event">
    </div>
    <div class="col-md-6 text-md-left p-4">
      <h1 style="font-variant: small-caps">Additional Information</h1>
      <p>John Kascenska of East Burke was born in Torrington, Connecticut. John’s family moved to Vermont in 1969 and he has lived in the Northeast Kingdom since 1992 raising two sons. John is a graduate of Poultney High School, Lyndon State College, and he holds graduate degrees in from Virginia Polytechnic Institute & State University in Recreation, and North Carolina State University in Forestry. John worked in higher education for 32 years. John’s primary work affiliation has been in the outdoor recreation industry given his lifelong interest outdoor recreation activities that include hunting, fishing, skiing, mountaineering, and mountain biking.</p>
      <p>Throughout his 30 years living in the Northeast Kingdom, he has actively served his community as President of the Burke Area Chamber of Commerce, and Treasurer and President of Lyndon Rescue, Inc. He is a current Trustee of the Burke Mountain Club, Corporator & Trustee of Northeastern Vermont Regional Hospital, and Honorary member of the Northern Vermont University – Lyndon Alumni Council. John is a twice elected Justice of the Peace for the Town of Burke.</p>
      <p>John is the founder and owner of Kingdom Adventures Mountain Guides, LLC, a Vermont-based business that provides nationally recognized certification first aid level programs in urban and wilderness medicine, and avalanche education safety programs. He also works with a team of mountain biking trail ambassadors toward their engagement and educational work with thousands of visitors who visit the Northeast Kingdom during the summer season.</p>
      <p>John was honored to serve the towns of Caledonia 4 District and will be a strong voice in the State House to represent the towns in the Essex-Caledonia District well.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalendarRow"
}
</script>

<style scoped>
.event{
  background-image: url("../assets/John_and_Joe_Parade.jpg");
  background-color: rgba(0,0,0,0.5);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 450px;
}
a {
  font-weight: bold;
  color: rgb(79, 121, 168);
}
</style>
