<template>
  <div class="home">
    <HomePlacard></HomePlacard>
    <TalkingPoints></TalkingPoints>
    <DonateBox></DonateBox>
    <CallToAction></CallToAction>
    <QuotePhil></QuotePhil>
    <CalendarRow></CalendarRow>
    <FooterBar></FooterBar>
  </div>

</template>
<style lang="scss">
a {
  font-weight: bold;
  color: rgb(79, 121, 168);
}
</style>
<script>
import HomePlacard from '../components/HomePlacard';
import TalkingPoints from '../components/TalkingPoints';
import DonateBox from '../components/DonateBox';
import CallToAction from '../components/CallToAction';
import QuotePhil from '../components/QuotePhil';
import CalendarRow from '../components/CalendarRow';
import FooterBar from '../components/FooterBar';
// @ is an alias to /src

export default {
  name: 'HomeView',
  components:{
    HomePlacard,
    TalkingPoints,
    DonateBox,
    CallToAction,
    QuotePhil,
    CalendarRow,
    FooterBar,
  }
}
</script>
