<template>
  <div class="row bp bpBody mx-0 ">
    <div class="col-md-3 bpColumnL"><img src="../assets/HeadShot2.jpg" class="headShot" alt="John Kascenska Photo"></div>
    <div class="col-md-9 bpColumnR">
      <h1 style="font-variant: small-caps">About</h1>
      <p>On March 7, 2022, John was appointed to the Vermont House of Representatives by Governor Phil Scott to represent the Caledonia 4 District of Sutton, Lyndon, and Burke and served as a member of the House Committee on Commerce and Economic Development, and as a dedicated member of the VT National Guard & Veterans Affairs Caucus throughout his term of office.</p>
      <p>From his first day in office and throughout the 2022 legislative session, John was an active committee member of working with his legislative colleagues to craft final versions of various bills that were successfully passed into law by the General Assembly. This included historic economic development legislation to address healthcare shortages, training experiences to create career changes and upskilling for workers, and the provision of forgivable loans for businesses to get them back on their feet.</p>
      <p>John’s ability to listen to all perspectives through extensive and comprehensive committee testimony; asking thoughtful questions; and making informed decisions that take into consideration the impact of potential legislation on residents he represents are qualities he brought to and will continue to bring to the Statehouse.</p>
      <p>John’s leadership experience as a local business owner and in higher education with Vermont State Colleges – Lyndon Campus, involvement in community initiatives and events, support of local groups including Lyndon Rescue and the Burke Area Chamber of Commerce, and ability to work collaboratively are additional qualities he brings to his service to the community and to the legislature.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TalkingPoints"
}
</script>

<style scoped>
.bp{
  padding: 10vh 3vh 4vh 3vh;
}
.bpColumnR{
  text-align: left;
}
.headShot{
  width: 100%;
}
</style>
